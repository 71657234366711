import {
  collection,
  CollectionReference,
  onSnapshot,
  orderBy,
  Query,
  query,
  where,
} from 'firebase/firestore'
import { uniqBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import Room from 'src/models/Room'
import useAuth from './useAuth'
import useFirebase from './useFirebase'

export default function useRooms(): [boolean, Room[]] {
  const { db } = useFirebase()
  const [createdRooms, setCreatedRooms] = useState<Room[]>()
  const [participatedRooms, setParticipatedRooms] = useState<Room[]>()
  const [_, user] = useAuth()

  useEffect(() => {
    if (!user) return

    const roomsRef = collection(db, 'rooms') as CollectionReference<Room>

    const createdQuery = query<Room>(
      roomsRef,
      where('creator.id', '==', user?.uid),
      orderBy('createdAt', 'desc')
    )
    const createdRoomsUnsubscribe = subscribe(createdQuery, setCreatedRooms)

    const participatedQuery = query(
      roomsRef,
      where('participants', 'array-contains', user?.uid),
      orderBy('createdAt', 'desc')
    )
    const participatedRoomsUnsubscribe = subscribe(
      participatedQuery,
      setParticipatedRooms
    )

    return () => {
      createdRoomsUnsubscribe()
      participatedRoomsUnsubscribe()
    }
  }, [db, user])

  const rooms = useMemo(
    () =>
      uniqBy(
        [...(createdRooms ?? []), ...(participatedRooms ?? [])],
        'id'
      ).sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()),
    [createdRooms, participatedRooms]
  )

  return [!rooms, rooms ?? []]
}

function subscribe(q: Query<Room>, setRooms: (rooms: Room[]) => void) {
  return onSnapshot(q, (querySnapshot) => {
    const rooms: Room[] = []
    querySnapshot.forEach((doc) => {
      const room = doc.data() as Omit<Room, 'id'>
      rooms.push({ id: doc.id, ...room })
    })
    setRooms(rooms)
  })
}
