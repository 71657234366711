import type { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Layout from 'components/Layout'
import Home from 'components/Home'
import Loading from 'components/Loading'
import { useUserContext } from 'src/providers/UserProvider'

const SignIn = dynamic(() => import('components/SignIn'), { ssr: false })

const HomePage: NextPage = () => {
  const { loading, user } = useUserContext()

  if (loading) {
    return <Loading height="100vh" />
  }

  if (!user) {
    return (
      <Layout>
        <SignIn sx={{ marginTop: 32 }} />
      </Layout>
    )
  }

  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export default HomePage
