import { addDoc, collection, doc, setDoc, Timestamp } from 'firebase/firestore'
import useFirebase from 'hooks/useFirebase'
import RoomList from 'components/room/RoomList'
import RoomDialog from 'components/room/RoomDialog'
import { Button, Stack, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import Navbar from './Navbar'
import { RoomFormValues, useRoomFormDialog } from './room/form'
import { useUserContext } from 'src/providers/UserProvider'
import Loading from './Loading'

export default function Home() {
  const { db } = useFirebase()
  const { push } = useRouter()
  const { loading, user } = useUserContext()
  const { handleSubmit, control, isDialogOpen, openDialog, closeAndReset } =
    useRoomFormDialog()

  const onSubmit = handleSubmit(async (data) => createRoom(data))

  async function createRoom(values: RoomFormValues) {
    if (!user) return
    try {
      const participant = {
        id: user.uid,
        avatarUrl: user.photoURL,
        name: user.displayName,
      }
      const docRef = await addDoc(collection(db, 'rooms'), {
        createdAt: Timestamp.now(),
        creator: participant,
        name: values.roomName,
        participants: [user.uid],
      })
      const participantRef = doc(
        db,
        'rooms',
        docRef.id,
        'participants_v2',
        user.uid
      )
      setDoc(participantRef, participant)
      closeAndReset()
      push(`room/${docRef.id}`)
    } catch (e) {
      // TODO: open snackbar with an error
    }
  }

  if (loading) return <Loading height="100vh" />

  return (
    <>
      <Navbar />

      <Stack direction="column" mt={{ xs: 12, sm: 18 }}>
        <Stack
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" m={2}>
            Meetings
          </Typography>

          <Button variant="contained" onClick={openDialog}>
            Create New Meeting
          </Button>
        </Stack>

        <RoomList />
      </Stack>

      <RoomDialog
        control={control}
        isOpen={isDialogOpen}
        onClose={closeAndReset}
        onSubmit={onSubmit}
      />
    </>
  )
}
