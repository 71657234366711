import { Box, Grid, Typography } from '@mui/material'
import Loading from 'components/Loading'
import useRooms from 'hooks/useRooms'
import RoomCard from './RoomCard'

const minHeight = '75px'

export default function RoomList() {
  const [loading, rooms] = useRooms()

  return (
    <Box alignItems="center" mt="5">
      {loading ? (
        <Loading minHeight={minHeight} />
      ) : (
        <>
          {rooms.length ? (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
              minHeight={minHeight}
            >
              {rooms.map((room) => (
                <Grid item xs={4} sm={4} md={4} lg={3} key={room.id}>
                  <RoomCard room={room} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              minHeight={minHeight}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Hmm, no meetings?</Typography>
            </Grid>
          )}
        </>
      )}
    </Box>
  )
}
