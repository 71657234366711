import { Container } from '@mui/material'
import { SystemStyleObject, Theme } from '@mui/system'

interface LayoutProps {
  children: JSX.Element | JSX.Element[]
  styles?: SystemStyleObject<Theme>
}

export default function Layout({ children, styles }: LayoutProps) {
  return (
    <Container maxWidth={false} sx={{ ...layoutStyle, ...styles }}>
      {children}
    </Container>
  )
}

const layoutStyle = {}
